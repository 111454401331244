.App {
  height: 100%;
  width: 100%;
}

.content {
  min-height: 96.75%;
  height: fit-content;
  width: 100%;
}

.Nav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  margin: auto;
  background-color: rgb(27, 24, 48);
  color: rgb(132, 150, 199);
}

.Nav p {
  font-size: 4.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: center;
  flex-direction: row;
  align-self: center;
}

.Nav img {
  display: block;
  width: 4rem;
  height: auto;
  align-self: flex-end;
  margin-right: 2rem;
  margin-left: 2rem;
}

.Nav a {
  height: fit-content;
  width: fit-content;
  align-self: center;
}

.AddTodo {
  width: 100%;
  height: fit-content;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
  justify-content: space-around;
  color: rgb(19, 17, 32);
}

.AddTodo input {
  background-color: rgb(230, 108, 9);
  color: rgb(19, 17, 32);
  border: 0.2rem solid rgb(230, 108, 9);
  border-radius: 0.5rem;
  height: 4rem;
  width: 50%;
  min-width: fit-content;
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.AddTodo button {
  background-color: rgb(230, 108, 9);
  color: rgb(19, 17, 32);
  border: 0.2rem solid rgb(230, 108, 9);
  border-radius: 0.5rem;
  height: 4rem;
  width: fit-content;
  font-size: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.AddTodo button:hover {
  background-color: transparent;
  color: rgb(230, 108, 9);
  cursor: pointer;
}

.AddTodo p {
  color: rgb(230, 108, 9);
  margin-top: 0.5rem;
}

.TodoList {
  width: 100%;
  height: fit-content;
}

.Todo {
  height: fit-content;
  width: 100%;
  text-align: center;
  color: rgb(230, 108, 9);
  justify-content: space-around;
}

.Todo p {
  font-size: 2rem;
  border-bottom: 0.5rem;
  border-color: rgb(230, 108, 9);
}

.Todo button {
  background-color: rgb(230, 108, 9);
  border: 0.2rem solid rgb(230, 108, 9);
  color: rgb(19, 17, 32);
  border-radius: 0.5rem;
  height: 2.5rem;
  width: fit-content;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Todo button:hover {
  background-color: transparent;
  color: rgb(230, 108, 9);
  cursor: pointer;
}

.Footer {
  width: 100%;
  height: 3%;
  margin: auto;
  background-color: rgb(27, 24, 48);
  color: rgb(132, 150, 199);
  text-align: center;
  align-items: center;
}

.Footer p {
  font-size: 1.5rem;
  height: fit-content;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 0.2rem;
}
